.header
    width: 100%
    height: 3rem
    position: fixed
    right: 0
    z-index: 10
    @media screen and (max-width: 768px)
        width: 90%
    .parentClass
        position: relative
        background: transparent
        height: 100%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: flex-end
        margin: 1rem
        gap: 1rem
        @media screen and (max-width: 768px)
            margin: 0.5rem
        .logo
            height: 5rem
            width: auto
            object-fit: contain
            @media screen and (max-width: 768px)
                height: 1.5rem
        .stats
            position: relative
            height: 3rem
            @media screen and (max-width: 768px)
                height: 1.5rem
            .stats-text-div
                position: absolute
                right: .4rem
                bottom: 50%
                transform: translateY(50%)
                color: black
                font-size: 1.2rem
                text-align: center
                width: 42%
                @media screen and (max-width: 768px)
                    right: .2rem
                    font-size: .8rem
            .stats-img
                height: 100%
                width: auto
                object-fit: contain
            .gold-div
                position: absolute
                right: .4rem
                bottom: 50%
                transform: translateY(50%)
                text-align: center
                font-size: 1.2rem
                color: black
                width: 67%
                @media screen and (max-width: 768px)
                    right: .2rem
                    font-size: .8rem
            .stamina-div
                position: absolute
                background: #fff
                width: 75%
                height: 1.3rem
                bottom: .25rem
                right: 0rem
                border: 3px solid #44949c
                border-radius: .5rem
                text-align: center
                @media screen and (max-width: 768px)
                    height: .65rem
                    bottom: .125rem
                    right: 0rem
                    font-size: .4rem
                .stamina-bar
                    height: 100%
                    color: black
                    font-weight: bold
                    width: 50%
                    background: #08e4ec
                    text-align: center

