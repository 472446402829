.parent{
    position: absolute;
    height: 100vh;
    .child-flex{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.logo{
    width: 90%;
    height: auto;
    object-fit: contain;
}

.profile_div{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    .profile_btn{
        width: 2rem;
        height: auto;
        object-fit: contain;
    }
}

main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #50747c;
    border-radius: 0 2rem 2rem 0;
    width: 12rem;
    height: 84%;
    margin-top: 3vh;
}

aside {
    height: auto;
}

.btn-container {
    position: absolute;
    top: 1vh;
    z-index: 1;
}

.container a {
    color: #f9fafb;
    text-decoration: none;
    font-weight: bold;
    display: block;
}

.menuFlex{
    .icon{
        width: 80%;
        height: auto;
        object-fit: contain;
    }
    .icon:hover{
        background: transparent;
    }
}

.menuIcon{
    width: auto;
    height: 1.5rem;
    object-fit: contain;
    z-index: 1;
}

.profile-section{
    position: relative;
    width: 12rem;
    height: auto;
    .profile-bar{
        width: 100%;
        height: auto;
    }
    .account{
        position: absolute;
        bottom: .3rem;
        left: 50%;
        transform: translateX(-50%);
        font-weight: bold;
        font-family: 'berlin-sans-fb-demi-bold', sans-serif;
        font-size: 1.5rem;
        text-align: center;
        color: black;
    }
    .profile-dp{
        position: absolute;
        left: 50%;
        transform: translateX(-53%);
        object-fit: contain;
        border-radius: 50%;
        width: 4rem;
    }
}

@media screen and (max-width: 768px){
    .profile-section{
        width: 8.5rem;
        .account{
            font-size: 1.2rem;
        }
        .profile-dp{
            width: 2.5rem;
        }
    }
    .menuFlex{
        .icon{
            width: 7rem;
        }
    }
    main{
        width: 8.5rem;
    }
    .menuIcon{
        width: 1.5rem;
    }
    .profile_div{
        gap: .5rem;
        .profile_btn{
            width: 1.5rem;
        }
    }
}