@import 'reset.sass'
@import 'global.sass'

@font-face
  font-family: 'berlin-sans-fb-demi-bold'
  src: url('./Assets/Font-Style/BRLNSDB.TTF') format('truetype')
  font-weight: normal
  font-style: normal


body
  font-family: 'berlin-sans-fb-demi-bold', sans-serif
  overflow-x: hidden
  background-color: black
  color: white
  background-image: url('./Assets/bg.png')
  background-size: cover
  background-repeat: no-repeat
  background-position: center
  background-attachment: fixed
  & iframe
    display: none

.center_align_container
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center